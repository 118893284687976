@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 210 60% 20.5%;
    --primary-foreground: 215 19% 35%;
    --secondary: 45 100% 51%;
    --secondary-foreground: 213 97% 13%;
    --secondary-btn: 45 100% 51%;
    --tertiary-hover: 0 0% 80%;
    --tertiary-btn: 0 0% 80%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 349 100% 55.5%;
    --action: 188 78% 41%;
    --action-foreground: 188 78% 41%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --neutral: 216 87% 42%;
    --neutral-color: 216 50% 96%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    font-family: 'Roboto', sans-serif;
  }
  .dark {
    --secondary-btn: "#FFC107";
    --tertiary-btn: "#6EAED2";
    /* this is for background color*/
    --background: 215 32.8% 22.7%;
    /* this is for text color*/
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 210 100% 55.5%;
    /* this is for heading color*/
    --primary-foreground: 203 21.0% 68.2%;  
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 349 100% 55.5%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    /* this is for card color*/
    --accent: 206 22.6% 41.6%;
    --accent-foreground: 349 100% 55.5%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    font-family: 'Roboto', sans-serif;
  }

  .black {
    --secondary-btn: "#FFC107";
    --tertiary-btn: "#6EAED2";
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 210 100% 55.5%;
    --primary-foreground: 360 10% 60%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 349 100% 55.5%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 349 100% 55.5%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    font-family: 'Roboto', sans-serif;
  }
}

@layer base {
  :root {
    --chart-1: 349 100% 55.5%;
    --chart-2: 0 0 90;
    --chart-3: 0 0 83;
    --chart-4: 0 0 64;
    --chart-5: 27 87% 67%;
    font-family: 'roboto', sans-serif;
    font-feature-settings: "cv02", "cv03", "cv04", "cv11", "salt";
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }

  * {
    @apply border-border;
  }

  @supports (font-variation-settings: normal) {
    :root {
      font-family: 'roboto', sans-serif;
      font-feature-settings: "cv02", "cv03", "cv04", "cv11", "salt";
    }
  }

  body {
    @apply bg-background text-foreground;
    font-family: 'roboto', sans-serif;
  }
  
}

@layer base {
  .leaflet-container,
  .leaflet-marker-icon,
  .leaflet-marker-shadow {
    all: revert;
  }
  .js-plotly-plot svg {
    display: inline;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
